import Layout from '@/views/layouts/main'
import API from '@/API.js'
import ProductoSrv from '@/services/ProductoSrv.js'
import CategoriaProductosSrv from '@/services/CategoriaProductosSrv.js'
import PerfilVelocidadCpesSrv from '@/services/PerfilVelocidadCpesSrv.js'
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: 'EdicionProducto',
  props: ['id'],
  components: { Layout,VueEasyLightbox },
  data() {
    return {
      baseUrl: window.location.origin, 
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      categorias: [],
      categoriasCargadas: false, // Bandera
      modo: 'nuevo',
      producto: {
        nombre: '',
        modelo: '',
        descripcion: '',
        activo: 1,
        id_categoria: null,
        precio: 0,
        anchura: 0,
        altura: 0,
        profundidad: 0,
        peso: 0,
        id_perfil_velocidad_cpes: null,
        id_unidad_medida: null,
        servicio: 0,
        /**
         * fotos = [
         *      {
         *          id: string,     // ID cuando ya está registrado en el servidor
         *          principal: boolean,
         *          file: File,
         *          src: string
         *      },
         *      ...,
         *      {...}
         * ]
         */
        fotos: []
      },
      productoCargado: false, // Bandera
      perfilesVelocidad: [],
      perfilesVelocidadCargados: false, // Bandera
      perfilesVelocidadVisibles: false,
      unidades_medida: [],
      bandera_spinner: false,
      index: 0, // default: 0
      visibleCaption: false,
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function() {
    var self = this

    // Selección del modo
    if (this.$route.path.indexOf('nuevo') >= 0) this.modo = 'nuevo'
    else this.modo = 'edicion'

    // Cargas iniciales
    if (self.modo == 'edicion') {
      self.cargarProducto()
      self.cargarFotosProducto()
    }

    self.cargarCategorias()
    self.cargarPerfilesVelocidad()
    self.cargarUnidadesMedida()

    // Procesos que se ejecutarán siempre que toda la información ya esté cargada
    iu.bus.on(['producto-cargado', 'categorias-cargadas', 'perfiles-cargados'],
      function() {
        // Si no está cargado todo, evita continuar
        if (
          !(
            self.categoriasCargadas &&
            self.perfilesVelocidadCargados &&
            self.productoCargado
          )
        )
          return

        // Si la categoría necesita los perfiles de velocidad, entonces activa la visibilidad
        // de los perfiles
        if (self.categoriaNecesitaPerfilVelocidadCpes(self.producto.id_categoria))
          self.perfilesVelocidadVisibles = true
      }
    )

    // Proceso que se ejecutará cuando las fotos estén cargadas
    iu.bus.on('fotos-cargadas', function() {})
  },

  methods: {
    showCaptiomImg(index) {
      this.index = index;
      this.visibleCaption = true;
    },
    handleCaptionHide() {
      this.visibleCaption = false;
    },

    actualizar: function() {
      var self = this

      var producto = Object.assign({}, self.producto)

      self.bandera_spinner = true

      if (producto.anchura == null) delete producto.anchura
      if (producto.altura == null) delete producto.altura
      if (producto.profundidad == null) delete producto.profundidad
      if (producto.peso == null) delete producto.peso

      /** Verificación de algunos datos importantes */
      if (producto.nombre == '') {
        iu.msg.warning('Es necesario un nombre')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      if (producto.id_categoria == null) {
        iu.msg.warning('Es necesario seleccionar una categoría para el producto')
        self.$refs.id_categoria.focus()
        self.bandera_spinner = false
        return
      }

      if(producto.precio == null || producto.precio == ''){
        iu.msg.warning('El precio no puede estar vacío mínimo el valor 0')
        self.$refs.precio.select()
        self.bandera_spinner = false
        return
      }

      if(producto.servicio == 0 && producto.id_unidad_medida == null) {
        iu.msg.warning('Es necesario seleccionar una unidad de medida')
        self.$refs.unidad_medida.focus()
        self.bandera_spinner = false
        return
      }

      if(producto.descripcion.length > 64141) {
        iu.msg.warning('El límite de 64,141 caracteres permitido para la descripción del producto')
        self.$refs.descripcion.select()
        self.bandera_spinner = false
        return
      }

      if (
        self.categoriaNecesitaPerfilVelocidadCpes(producto.id_categoria) &&
        producto.id_perfil_velocidad_cpes == null
      ) {
        iu.msg.warning('Es necesario un perfil de velocidad para el producto')
        self.$refs.perfil_velocidad.focus()
        self.bandera_spinner = false
        return
      }

      if(producto.servicio == 1) delete producto.id_unidad_medida
      /** Fin de Verificación de algunos datos importantes */

      if(typeof producto.anchura !== 'number') producto.anchura = 0
      if(typeof producto.altura !== 'number') producto.altura = 0
      if(typeof producto.profundidad !== 'number') producto.profundidad = 0
      if(typeof producto.peso !== 'number') producto.peso = 0

      ProductoSrv.actualizar(producto).then(response=>{
        iu.msg.success('Se actualizó correctamente')
        self.cargarFotosProducto() // Refresca las fotos del producto
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    cancelar: function() {
      this.$router.go(-1)
    },

    cargarFotosProducto: function() {
      var idProducto = this.$route.params.id
      var self = this

      self.producto.fotos = []
      ProductoSrv.fotosJSON(idProducto).then(response => {
        let fotos = response.data
        fotos.forEach(ft => {
          let newFoto = Object.assign(ft, {
            src: `${self.API}/imagenes/${ft.id}?ruta=productos&_tk=${self.tk}`
          })
          self.producto.fotos.push(newFoto)
        })
        self.$emit('fotos-cargadas')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las fotos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarProducto: function() {
      var self = this
      ProductoSrv.productoJSON(self.id).then(response => {
        var producto = response.data
        Object.assign(self.producto, producto)
        self.productoCargado = true
        self.$emit('producto-cargado')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el producto'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarCategorias: function() {
      var self = this

      CategoriaProductosSrv.categoriasJSON().then(response => {
        self.categorias = response.data
        self.categoriasCargadas = true
        self.$emit('categorias-cargadas')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las categorias'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPerfilesVelocidad: function() {
      var self = this

      PerfilVelocidadCpesSrv.perfilesJSON().then(response => {
        let perfiles = response.data
        self.perfilesVelocidad = perfiles
        self.perfilesVelocidadCargados = true
        self.$emit('perfiles-cargados')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los perfules de velocidad'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarUnidadesMedida () {
      let self = this
      ArticuloSrv.unidadesMedidaJSON().then(response => {
        self.unidades_medida = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las unidades de medida'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    categoriaNecesitaPerfilVelocidadCpes: function(id) {
      var idCategoria = this.producto.id_categoria

      var cats = this.categorias.filter(function(cat) {
        return cat.id == idCategoria
      })

      if (cats.length > 0 && cats[0].necesita_perfil_velocidad_cpes) return true
      return false
    },

    seleccionarPerfil: function(perfil) {
      this.producto.id_perfil_velocidad_cpes = perfil.id
    },

    /**
     * Control de fotos del producto
     */
    agregarFoto: function() {
      var self = this

      var input = document.createElement("input")
      input.setAttribute('type', 'file')
      input.setAttribute('multiple', '')

      input.click()
      input.addEventListener('change', function(e) {
        var inputFoto = e.target

        for(var i = 0; i < inputFoto.files.length; i++){
          let foto = {
            id: null,
            file: inputFoto.files[i],
            src: null,
            principal: false
          }

          var reader = new FileReader()

          reader.onload = function(e) {
            foto.src = e.target.result
            // Pone como principal a la primera foto agregada
            if (self.producto.fotos.length == 0) foto.principal = true
            self.producto.fotos.push(foto)
          }

          reader.readAsDataURL(foto.file)
        }
      })
    },
    
    eliminarFoto: function(index) {
      this.producto.fotos.splice(index, 1)

      // Detección de foto principal
      var fps = this.producto.fotos.filter(function(f) {
        return f.principal == true
      })

      // Si no hubo alguna foto principal se asigna una por default
      if (fps.length == 0 && this.producto.fotos.length > 0)
        this.producto.fotos[0].principal = true
    },

    guardar: function() {
      var self = this

      var producto = Object.assign({}, self.producto)

      self.bandera_spinner = true

      /** Verificación de algunos datos importantes */
      if (producto.nombre == '') {
        iu.msg.warning('Es necesario un nombre')
        self.$refs.nombre.select()
        self.bandera_spinner = false
        return
      }

      if (producto.id_categoria == null) {
        iu.msg.warning('Es necesario seleccionar una categoría para el producto')
        self.$refs.id_categoria.focus()
        self.bandera_spinner = false
        return
      }

      if(producto.precio == null || producto.precio == ''){
        iu.msg.warning('El precio no puede estar vacío mínimo el valor 0')
        self.$refs.precio.select()
        self.bandera_spinner = false
        return
      }

      if(producto.servicio == 0 && producto.id_unidad_medida == null) {
        iu.msg.warning('Es necesario seleccionar una unidad de medida')
        self.$refs.unidad_medida.focus()
        self.bandera_spinner = false
        return
      }

      if(producto.descripcion.length > 64141) {
        iu.msg.warning('El límite de 64,141 caracteres permitido para la descripción del producto')
        self.$refs.descripcion.select()
        self.bandera_spinner = false
        return
      }

      if (
        self.categoriaNecesitaPerfilVelocidadCpes(producto.id_categoria) &&
        producto.id_perfil_velocidad_cpes == null
      ) {
        iu.msg.warning('Es necesario un perfil de velocidad para el producto')
        self.$refs.perfil_velocidad.focus()
        self.bandera_spinner = false
        return
      }

      if(producto.servicio == 1) delete producto.id_unidad_medida
      /** Fin de Verificación de algunos datos importantes */

      if( typeof producto.anchura !== 'number') producto.anchura = 0
      if( typeof producto.altura !== 'number') producto.altura = 0
      if( typeof producto.profundidad !== 'number') producto.profundidad = 0
      if( typeof producto.peso !== 'number') producto.peso = 0

      ProductoSrv.guardar(producto).then(response => {
        var id = response.data
        self.$router.replace({
          name: 'edicionProducto',
          params: {
            id: id,
            nuevoProducto: true
          }
        })

        self.producto.id = id
        self.modo = 'edicion'
        iu.msg.success('Se guardó correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    ponerPrincipal: function(foto) {
      var self = this

      self.producto.fotos.forEach(function(ft) {
        ft.principal = false
      })

      foto.principal = true
    },

    servicioProducto: function() {
      var self = this

      let categoria = self.categorias.find((cat) => {
        return cat.id == self.producto.id_categoria
      })

      if(categoria) self.producto.servicio = categoria.servicios
      else self.producto.servicio = 0
    },

    visualizarPerfilesVelocidad: function() {
      var self = this
      var idCategoria = self.producto.id_categoria

      var categoria = self.categorias.find((cat) => {
        return cat.id == idCategoria
      })

      if (categoria && categoria.necesita_perfil_velocidad_cpes) {
        self.perfilesVelocidadVisibles = true
      } else {
        self.perfilesVelocidadVisibles = false
        self.producto.id_perfil_velocidad_cpes = null
      }
    }
  },
}
<template>
  <Layout :tituloPagina="`Productos | ${modo == 'nuevo' ? 'Nuevo' : 'Edición'}`">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          {{ modo == 'nuevo' ? 'Nuevo producto' : 'Edición de producto' }}
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="modo == 'nuevo' ? guardar() : actualizar()"
                  v-if="!bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="cancelar()"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <ul
          class="nav nav-tabs nav-tabs-custom nav-justified"
          role="tablist"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              data-bs-toggle="tab"
              href="#genaral"
              role="tab"
            >
              <span class="d-block d-sm-none">
                <i class="fab fa-wpforms"></i>
              </span>
              <span class="d-none d-sm-block">General</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              data-bs-toggle="tab"
              href="#fotos"
              role="tab"
            >
              <span class="d-block d-sm-none">
                <i class="fas fa-photo-video"></i>
              </span>
              <span class="d-none d-sm-block">Fotos</span>
            </a>
          </li>
          <li class="nav-item"></li>
          <li class="nav-item"></li>
          <li class="nav-item"></li>
        </ul>
        <div class="tab-content p-3">
          <div class="tab-pane active" id="genaral" role="tabpanel">
            <div class="row">
              <div class="col-md-6">
                <label>Nombre</label>
                <input
                  class="form-control"
                  placeholder="Nombre del producto"
                  ref="nombre"
                  name="nombre"
                  v-model="producto.nombre"
                />
              </div>
              <div class="col-md-5 solo-modo-producto">
                <label>Modelo</label>
                <input
                  class="form-control"
                  placeholder="Modelo del producto"
                  ref="modelo"
                  name="modelo"
                  v-model="producto.modelo"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label>Descripción</label>
                <textarea
                  class="form-control"
                  name="descripcion"
                  ref="descripcion"
                  placeholder="Descripción del producto"
                  maxlength="64141"
                  rows="10"
                  v-model="producto.descripcion"
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <label>Estado</label>
                <select
                  name="activo"
                  class="form-select"
                  v-model="producto.activo"
                >
                  <option value="1" selected>Activo</option>
                  <option value="0">Inactivo</option>
                </select>
              </div>
              <div class="col-md-3">
                <label>Categoría</label>
                <select
                  ref="id_categoria"
                  name="id_categoria"
                  class="form-select"
                  v-model="producto.id_categoria"
                  @change="
                    visualizarPerfilesVelocidad(),
                    servicioProducto()
                  "
                >
                  <option :value="null">Seleccionar</option>
                  <option
                    v-for="categoria in categorias"
                    :value="categoria.id"
                    :key="categoria.id"
                  >
                    {{ categoria.nombre }}
                  </option>
                </select>
              </div>
              <div class="col-md-3" v-show="producto.id_categoria != null && !producto.servicio">
                <label>Unidad de medida</label>
                <select
                  class="form-select"
                  name="unidad_medida"
                  ref="unidad_medida"
                  v-model="producto.id_unidad_medida"
                >
                  <option :value="null">Seleccionar unidad</option>
                  <option
                    :value="unidad.id"
                    v-for="unidad in unidades_medida"
                    :key="unidad.id"
                  >
                    {{ unidad.nombre }}
                  </option>
                </select>
              </div>
              <div class="col-md-3" v-show="perfilesVelocidadVisibles">
                <label>Perfiles de velocidad</label>
                <select
                  class="form-select"
                  name="perfil_velocidad"
                  ref="perfil_velocidad"
                  v-model="producto.id_perfil_velocidad_cpes"
                >
                  <option :value="null">Seleccionar perfil</option>
                  <option
                    :value="perfil.id"
                    v-for="perfil in perfilesVelocidad"
                    :key="perfil.id"
                  >
                    {{ perfil.nombre }} - Descarga:
                    {{ perfil.descarga }} kbps, Carga:
                    {{ perfil.carga }} kbps
                  </option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3">
                <label>Precio</label>
                <div class="input-group">
                  <span class="input-group-text">
                    {{ monedaSistema.simbolo }}
                  </span>
                  <input
                    ref="precio"
                    type="number"
                    class="form-control text-right"
                    oninput="this.value = Math.abs(this.value)"
                    v-model="producto.precio"
                    placeholder="0.00"
                  />
                  <span class="input-group-text">
                    {{ monedaSistema.codigo }}
                  </span>
                </div>
              </div>
            </div>

            <div class="row solo-modo-producto">
              <div class="col-md-3">
                <label>Anchura</label>
                <div class="input-group">
                  <input
                    type="number"
                    step="0.25"
                    class="form-control text-right"
                    name="anchura"
                    v-model="producto.anchura"
                    placeholder="0"
                  />
                  <div class="input-group-text">
                    cms
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <label>Altura</label>
                <div class="input-group">
                  <input
                    type="number"
                    step="0.25"
                    class="form-control text-right"
                    name="altura"
                    v-model="producto.altura"
                    placeholder="0"
                  />
                  <div class="input-group-text">
                    cms
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <label>Profundidad</label>
                <div class="input-group">
                  <input
                    type="number"
                    step="0.25"
                    class="form-control text-right"
                    name="profundidad"
                    v-model="producto.profundidad"
                    placeholder="0"
                  />
                  <div class="input-group-text">
                    cms
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <label>Peso</label>
                <div class="input-group">
                  <input
                    type="number"
                    step="0.25"
                    class="form-control"
                    name="peso"
                    v-model="producto.peso"
                    placeholder="0"
                  />
                  <div class="input-group-text">
                    Kgs
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="fotos" role="tabpanel">
            <div class="text-right mb-2">
              <button
                class="btn btn-outline-success btn-sm"
                @click="agregarFoto()"
              >
                <i class="mdi mdi-plus-thick"></i>
                foto
              </button>
            </div>
            <div id="fotosProducto" class="dropzone">
              <div class="dz-message needsclick cursor" v-if="producto.fotos.length == 0" @click="agregarFoto()">
                <div class="mb-3">
                  <i class="display-4 text-muted bx bx-cloud-upload"></i>
                </div>
                <h5>Haga clic para cargar las imagenes</h5>
              </div>
              <table class="lista" v-if="producto.fotos.length > 0">
                <tr>
                  <td
                    v-for="(foto, index) in producto.fotos"
                    class="marcoFoto"
                    :key="foto.id"
                  >
                    <button
                      class="btnEliminarFoto btn btn-danger btn-sm btn-rounded"
                      title="Borrar foto"
                      @click="eliminarFoto(index)"
                    >
                      <i class="mdi mdi-close-thick"></i>
                    </button>

                    <button
                      class="btnPrincipal btn btn-sm btn-rounded"
                      title="Foto principal"
                      :class="{
                        'btn-light': !foto.principal,
                        'btn-success': foto.principal
                      }"
                      @click="ponerPrincipal(foto)"
                    >
                      <i
                        class="bx bx-circle"
                        v-show="!foto.principal"
                      ></i>
                      <i
                        class="bx bx-check-circle"
                        v-show="foto.principal"
                      ></i>
                    </button>
                    <img
                      :src="`${API}/imagenes/${foto.id}?tipo_foto=256x256&ruta=productos&_tk=${tk}`"
                      v-if="foto.id != null && foto.id != undefined"
                      class="iconproductos"
                      @click="() => showCaptiomImg(index)"
                    />
                    <img
                      :src="foto.src"
                      v-if="
                        (foto.id == null || foto.id == undefined) && foto.src != ''
                      "
                      @click="() => showCaptiomImg(index)"
                    />
                  </td>
                </tr>
              </table>
              <vue-easy-lightbox
                :visible="visibleCaption"
                :imgs="producto.fotos"
                :index="index"
                @hide="handleCaptionHide"
              ></vue-easy-lightbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right">
      <div class="btn-group">
        <button @click="cancelar()" class="btn btn-secondary">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button
          class="btn btn-success"
          @click="actualizar()"
          v-if="modo == 'edicion'"
          :disabled="bandera_spinner"
        >
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          Actualizar
        </button>
        <button
          class="btn btn-success"
          @click="guardar()"
          v-if="modo == 'nuevo'"
          :disabled="bandera_spinner"
        >
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          Guardar
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script src="./EdicionProducto.js"></script>

<style scoped>
.btnEliminarFoto {
  position: absolute;
  right: 0px;
  top: 0px;
}
.btnPrincipal {
  position: absolute;
  right: 33px;
  top: 0px;
}
.marcoFoto {
  position: relative;
}
#fotosProducto {
  overflow-x: auto;
  /*border: thin solid #dedede;*/
  position: relative;
}
#fotosProducto table {
  height: 300px;
}
input[name='fotos[]'] {
  display: none;
}
img {
  cursor: pointer;
  max-height: 350px;
}
.iconproductos{
  width:350px;
  height:350px;
}
</style>